import "./App.css";
import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import ContactForm from "./components/ContactForm";
import Offerings from "./components/Offerings";
import Footer from "./components/Footer";
import DesignBenefits from "./components/DesignBenefits";
import AboutPage from "./pages/AboutPage";
import LogoDesigningPage from "./pages/LogoDesigningPage";
import UIUXDesigningPage from "./pages/UIUXDesigningPage";
import StationeryDesigningPage from "./pages/StationeryDesigningPage";
import NavigationBar from "./components/NavigationBar";

import WebsiteDesigningPage from "./pages/WebsiteDesigningPage";
import MarketingPage from "./pages/MarketingPage";
import MobileAppDevelopmentPage from "./pages/MobileAppDevelopmentPage";

import { useLocation } from "react-router-dom";
import BrandGoals from "./pages/BrandGoals";

function App() {
  const location = useLocation();

  return (
    <div>
      <div>
        {location.pathname === "/brand-goals" ? null : <NavigationBar />}
      </div>

      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/contact" element={<ContactForm />} />
        <Route path="/our-services" element={<Offerings />} />
        <Route path="/about-us" element={<AboutPage />} />
        <Route path="/logo-design" element={<LogoDesigningPage />} />
        <Route path="/uiux-design" element={<UIUXDesigningPage />} />
        <Route
          path="/stationery-design"
          element={<StationeryDesigningPage />}
        />
        <Route path="/website-design" element={<WebsiteDesigningPage />} />
        <Route path="/marketing" element={<MarketingPage />} />
        <Route
          path="/mobile-app-development"
          element={<MobileAppDevelopmentPage />}
        />

        <Route path="/brand-goals" element={<BrandGoals />} />
      </Routes>

      {location.pathname === "/contact" ||
      location.pathname === "/brand-goals" ? null : (
        <div>
          <DesignBenefits />
        </div>
      )}

      <div>{location.pathname === "/brand-goals" ? null : <Footer />}</div>
    </div>
  );
}

export default App;
