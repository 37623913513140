import React, { useState } from "react";
import "./CustomProcess.css";
import firstImage from "../assets/images/customProcessImages/1.png";
import secondImage from "../assets/images/customProcessImages/2.png";

const CustomProcess = (props) => {
  const [dataOne, setDataOne] = useState(props?.customProcessDataOne);
  const [dataTwo, setDataTwo] = useState(props?.customProcessDataTwo);
  return (
    <div className="custom-process">
      <div className="container py-5">
        <div className="row custom-process-content">
          <div className="col-lg-6">
            {dataOne.map((section, sectionIndex) => (
              <div key={sectionIndex}>
                <p className="font-face-Montserrat-Bold">{section.title}</p>
                <ul className="text-white">
                  {section.items.map((item, itemIndex) => (
                    <li className="font-face-Montserrat-Medium" key={itemIndex}>{item}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
          <div className="col-lg-6 d-flex flex-column align-items-center justify-content-center">
            <img className="img-fluid" src={firstImage} alt="1" />
          </div>
        </div>

        <div className="row custom-process-content-reverse mt-5">
          <div className="col-lg-6 d-flex flex-column align-items-center justify-content-center">
            <img className="img-fluid" src={secondImage} alt="1" />
          </div>
          <div className="col-lg-6">
            {dataTwo.map((section, sectionIndex) => (
              <div key={sectionIndex}>
                <p className="font-face-Montserrat-Bold">{section.title}</p>
                <ul className="text-white">
                  {section.items.map((item, itemIndex) => (
                    <li className="font-face-Montserrat-Medium" key={itemIndex}>{item}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomProcess;
