import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter } from "react-router-dom";
import "./assets/styles/fonts/Montserrat-Bold/Montserrat-Bold.ttf";
import "./assets/styles/fonts/Montserrat-Regular/Montserrat-Regular.ttf";
import "./assets/styles/fonts/Montserrat-SemiBold/Montserrat-SemiBold.ttf";
import "./assets/styles/fonts/Montserrat-Medium/Montserrat-Medium.ttf";
import "./assets/styles/fonts/Montserrat-Italic/Montserrat-Italic.ttf";
import "./assets/styles/fonts/Montserrat-Light/Montserrat-Light.ttf";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
