import React, { useEffect, useState } from "react";
import horizontalLine from "../assets/images/pricingImages/Line 1.png";
import Aos from "aos";
import "aos/dist/aos.css";

const ClientTestimony = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  });
  const [testimonialData, setTestimonialData] = useState([
    {
      id: 1,
      commaImage: require("../assets/images/clientTestimonyImages/testIcon.png"),
      description:
        "Patronus Pixels exceeded our expectations with their logo design – a perfect blend of creativity and professionalism. The website and web app designs showcased their commitment to seamless user experience. Truly impressed!",
      circleImage: require("../assets/images/clientTestimonyImages/1.png"),
      userName: "Sarah David",
      companyName: "CEO of TechInnovate Solutions",
    },
    {
      id: 2,
      commaImage: require("../assets/images/clientTestimonyImages/testIcon.png"),
      description:
        "Working with Patronus Pixels on our branding was a game-changer. The team's dedication to understanding our vision and translating it into a cohesive brand identity was remarkable. Our product now stands out in the market!",
      circleImage: require("../assets/images/clientTestimonyImages/2.png"),
      userName: "Mark Tavez",
      companyName: "Founder of InnovateTech Labs",
    },
    {
      id: 3,
      commaImage: require("../assets/images/clientTestimonyImages/testIcon.png"),
      description:
        "Patronus Pixels transformed our concept into a visually stunning and user-friendly website. Their attention to detail in UX/UI design made navigating our platform effortless. A highly recommended partner for web development!",
      circleImage: require("../assets/images/clientTestimonyImages/3.png"),
      userName: "Emily John",
      companyName: "CEO Media",
    },
  ]);
  return (
    <div className="testimonial-main text-white">
      <div className="container py-5 text-center">
        <h3
          className="font-face-Montserrat-Semi-Bold"
          data-aos="zoom-in"
          data-aos-duration="900"
        >
          TESTIMONIALS
        </h3>
        <img src={horizontalLine} alt="hr" />
        <p
          className="font-face-Montserrat-Regular"
          data-aos="zoom-in"
          data-aos-duration="900"
        >
          <span className="d-lg-block">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
            tellus,{" "}
          </span>
          luctus nec ullamcorper mattis, pulvinar dapibus leo.
        </p>

        <div className="row px-lg-5 py-2">
          {testimonialData.map((x, index) => {
            return (
              <div
                key={index}
                className="col-lg-4 px-lg-2 mb-4"
                data-aos="zoom-in"
                data-aos-duration="1300"
              >
                <div className="testimonial-inner px-3 px-md-3 pt-5 pb-2">
                  <img src={x.commaImage} alt="c-icon" />
                  <p className="font-face-Montserrat-Italic testimonial-description py-4">
                    {x.description}
                  </p>
                  <img src={x.circleImage} alt="" />
                  <div className="pt-2">
                    <p className="font-face-Montserrat-Medium m-0">
                      {x.userName}
                    </p>
                    <p className="font-face-Montserrat-Light">
                      {x.companyName}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ClientTestimony;
