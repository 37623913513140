import React, { useEffect } from "react";
import CustomProcess from "../components/CustomProcess";
import PortfolioBanner from "../components/PortfolioBanner";
import horizontalLine from "../assets/images/pricingImages/Line 1.png";
import Aos from "aos";
import "aos/dist/aos.css";

const WebsiteDesigningPage = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const BannerData = {
    bannerHeading: "Website Designing",
    bannerImg: require("../assets/images/websiteDesigningImages/websiteDesigningbackground.png"),
    bannerParaOne:
      "Embark on a Journey Through Our Esteemed Website Design Portfolio: Where Creativity",
    bannerParaTwo:
      "Blends with Purpose to Bring Websites to Life. Immerse Yourself in a Gallery that Resonates",
    bannerParaThree:
      "with Our Commitment to Crafting Engaging Online Experiences. Every Website Reflects ",
    bannerParaFour:
      "Precision, Zeal, and the Ability to Forge an Unforgettable Online Presence.",
  };

  const customProcessDataOne = [
    {
      title: "1. Project Discovery and Research",
      items: [
        "Define the project scope, objectives, and goals.",
        "Research the target audience, industry, and competitors.",
        "Identify key user personas and their needs.",
      ],
    },
    {
      title: "2. Planning and Strategy",
      items: [
        "Create a project plan and timeline.",
        "Develop a sitemap or site structure.",
        "Define the content strategy and information architecture.",
        "Establish goals for the website (e.g., lead generation, e-commerce, information sharing).",
      ],
    },
    {
      title: "3. Wireframing and Prototyping",
      items: [
        "Create wireframes or low-fidelity sketches to outline page layouts and content placement.",
        "Develop interactive prototypes to visualize the user flow and functionality.",
        "Gather feedback and iterate on the design.",
      ],
    },
    {
      title: "4. Visual Design",
      items: [
        "Design the website's visual identity, including colors, typography, and imagery.",
        "Create high-fidelity mockups or design comps for different pages.",
        "Ensure that the design aligns with the brand's guidelines and the project's objectives.",
      ],
    },
  ];

  const customProcessDataTwo = [
    {
      title: "5. Development",
      items: [
        "Choose a technology stack (e.g., HTML, CSS, JavaScript, CMS like WordPress).",
        "Write clean and well-structured code.",
        "Develop responsive design to ensure the website works on various devices (desktop, mobile, tablet).",
        "Implement interactive elements, animations, and functionality.",
      ],
    },
    {
      title: "6. Content Creation and Integration",
      items: [
        "Develop and optimize website content, including text, images, videos, and other media.",
        "Ensure content is SEO-friendly and follows best practices.",
        "Integrate content management systems (CMS) for easy updates and maintenance.",
      ],
    },
    {
      title: "7. Testing and Quality Assurance",
      items: [
        "Perform cross-browser and cross-device testing to ensure compatibility.",
        "Test website functionality, forms, and user interactions.",
        "Check for broken links and optimize website speed.",
        "Conduct usability testing with real users to gather feedback.",
      ],
    },
    {
      title: "8. Deployment",
      items: [
        "Choose a hosting platform and set up domain and hosting services.",
        "Upload the website files to the server.",
        "Configure security measures, such as SSL certificates.",
        "Conduct final checks to ensure everything is working as expected.",
      ],
    },
  ];

  return (
    <div>
      <PortfolioBanner BannerData={BannerData} />
      <div className="portfolio-inner py-5">
        <div className="text-center text-white">
          <h3
            className="font-face-Montserrat-Semi-Bold"
            data-aos="zoom-in"
            data-aos-duration="1300"
          >
            Our Proven Process from Concept to Creation
          </h3>
          <img src={horizontalLine} alt="hr" />
          <p
            className="font-face-Montserrat-Medium m-0"
            data-aos="zoom-in"
            data-aos-duration="1300"
          >
            "Step into Our Web Design Journey, a Fusion of Innovation and
            Purpose. Explore a Process that Captures Our Dedication to
            <span className="d-lg-block">
              Crafting Compelling Online Narratives for Brands. Each Phase
              Showcases Meticulous Craftsmanship and a Deep
            </span>
            <span className="d-lg-block">
              Understanding of How Design Shapes Unforgettable User
              Experiences."
            </span>
          </p>
        </div>
      </div>
      <CustomProcess
        customProcessDataOne={customProcessDataOne}
        customProcessDataTwo={customProcessDataTwo}
      />
    </div>
  );
};

export default WebsiteDesigningPage;
