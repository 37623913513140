import React, { useEffect } from "react";
import "../components/Experience.css";
import CompletionBar from "../components/CompletionBar";
import horizontalLine from "../assets/images/pricingImages/Line 1.png";
import Aos from "aos";
import "aos/dist/aos.css";

const Experience = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  });
  const experiences = [
    {
      year: "2016 - 2017 ",
      heading: "(Junior Graphics Designer)",
      company: "Batoota Pvt.Ltd",
      description: "Karachi, Pakistan",
    },
    {
      year: "2017 - 2020",
      heading: "(Senior Graphics & UX/UI Designer)",
      company: "Digital Visibility Concepts",
      description: "Burbank, California (Remote)",
    },

    {
      year: "2020 - 2022",
      heading: "(Senior Graphics & UX/UI Designer)",
      company: "Media-One-Group llc",
      description: "Burbank, California (Remote)",
    },
  ];

  return (
    <div className="box text-white">
      <div className="container">
        <div className="text-center">
          <h3
            className="font-face-Montserrat-Semi-Bold"
            data-aos="zoom-in"
            data-aos-duration="1300"
          >
            EXPERIENCE & SKILLS
          </h3>
          <img src={horizontalLine} alt="hr" />
          <p
            className="font-face-Montserrat-Regular"
            data-aos="zoom-in"
            data-aos-duration="1300"
          >
            <span className="d-lg-block">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
              tellus,{" "}
            </span>
            luctus nec ullamcorper mattis, pulvinar dapibus leo.
          </p>
        </div>

        <div className="row">
          {experiences.map((x, index) => (
            <div
              className="col-lg-4 col-md-4 col-sm-4 col-xs-12"
              key={index}
              data-aos="zoom-in"
              data-aos-duration="1300"
            >
              <div className="box-part text-start">
                <div className="title">
                  <h6 className="font-face-Montserrat-Regular">{x.year}</h6>
                  <h6 className="font-face-Montserrat-Regular">{x.heading}</h6>
                </div>
                <div className="heading">
                  <h4 className="font-face-Montserrat-Medium">{x.company}</h4>
                </div>

                <div className="text">
                  <span className="font-face-Montserrat-Regular">
                    {x.description}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>

        <CompletionBar />
      </div>
    </div>
  );
};

export default Experience;
