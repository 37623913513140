import React, { useEffect } from "react";
import "./HighlightedWorks.css";
import horizontalLine from "../assets/images/pricingImages/Line 1.png";
import Aos from "aos";
import "aos/dist/aos.css";
import redArrow from "../assets/images/selectedWorkSectionImages/rightTickrRedArrow.png";

const HighlightedWorks = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  });
  const highlightedItems = [
    {
      highlightedBanner: require("../assets/images/aboutImages/1.png"),
      heading: "Lions International",
      description:
        "Lion International is a community support web app that efficiently navigates community guidelines, ensuring users adhere to established do's and don'ts for a positive online environment.",

      link: "https://xd.adobe.com/view/9a9404b0-3e79-49cc-93a3-5d1c0a6494fe-cfc7/",
    },
    {
      highlightedBanner: require("../assets/images/aboutImages/2.png"),
      heading: "Black Tie & Jam",
      description:
        "Black Tie and Jam is your go-to website for culinary inspiration, seamlessly blending delectable recipes with captivating food travel and event blogs.",

      link: "https://www.figma.com/proto/E19ATRIlHwfdfATknrMMF9/Fodies---Food-Delivery-App?page-id=698%3A4516&type=design&node-id=698-11844&viewport=211%2C-626%2C0.09&t=SUiSAY2ekAtwn4XA-1&scaling=scale-down",
    },
    {
      highlightedBanner: require("../assets/images/aboutImages/3.png"),
      heading: "Foodies - Food Delivery App",
      description:
        "Foodies - Food Delivery App brings the world of culinary delights to your doorstep, providing a seamless mobile experience for all food enthusiasts craving their favorite dishes.",

      link: "https://xd.adobe.com/view/9a9404b0-3e79-49cc-93a3-5d1c0a6494fe-cfc7/",
    },
    {
      highlightedBanner: require("../assets/images/aboutImages/4.png"),
      heading: "StudyGenius.io",
      description:
        "StudyGenius.io is an AI-powered homework web app empowering students with intelligent tools, making homework a breeze through advanced artificial intelligence capabilities.",

      link: "https://www.figma.com/proto/M2kh1PPUntTi6eUwpYRshA/High-Fidelity-Study-Genius-User-Profile?page-id=0%3A1&node-id=1-158&starting-point-node-id=1%3A158&scaling=scale-down&t=L3f1wzXhSaSvM6O6-1",
    },
  ];
  return (
    <div className="highlighted-work-main text-white">
      <div className="container">
        <div className="text-center">
          <h3
            className="font-face-Montserrat-Semi-Bold"
            data-aos="zoom-in"
            data-aos-duration="1300"
          >
            SELECTED WORKS
          </h3>
          <img src={horizontalLine} alt="hr" />
          <p
            className="font-face-Montserrat-Regular"
            data-aos="zoom-in"
            data-aos-duration="1300"
          >
            <span className="d-lg-block">
              Explore Our Showcase of Cutting-Edge Websites, Mobile and Web
            </span>
            Applications That Redefines Digital Excellence.
          </p>
        </div>

        <div className="row py-5 px-lg-5">
          {highlightedItems.map((x, index) => {
            return (
              <div key={index} className="col-lg-6 col-md-6 mb-5">
                <div className="highlighted-inner">
                  <img
                    className="img-fluid"
                    src={x.highlightedBanner}
                    alt="hg"
                  />
                  <h4 className="font-face-Montserrat-Medium pt-2">
                    {x.heading}
                  </h4>
                  <p className="font-face-Montserrat-Regular">
                    {x.description}
                  </p>
                  <a href={x.link} target="_blank">
                    <button className="font-face-Montserrat-Medium highlighted-btn">
                      See Details
                      <img className="ms-2" src={redArrow} alt="right-icon" />
                    </button>
                  </a>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default HighlightedWorks;
