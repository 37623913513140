import React, { useState } from "react";
import "../components/CompletionBar.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const CompletionBar = () => {
  const [elements] = useState([
    {
      id: 1,
      imageSource: require("../assets/images/completitionBarImages/1.png"),
    },
    {
      id: 2,
      imageSource: require("../assets/images/completitionBarImages/2.png"),
    },
    {
      id: 3,
      imageSource: require("../assets/images/completitionBarImages/3.png"),
    },
    {
      id: 4,
      imageSource: require("../assets/images/completitionBarImages/4.png"),
    },
    {
      id: 5,
      imageSource: require("../assets/images/completitionBarImages/5.png"),
    },
    {
      id: 6,
      imageSource: require("../assets/images/completitionBarImages/6.png"),
    },
    {
      id: 7,
      imageSource: require("../assets/images/completitionBarImages/7.png"),
    },
    {
      id: 8,
      imageSource: require("../assets/images/completitionBarImages/8.png"),
    },
    {
      id: 9,
      imageSource: require("../assets/images/completitionBarImages/9.png"),
    },
    {
      id: 10,
      imageSource: require("../assets/images/completitionBarImages/10.png"),
    },
    {
      id: 11,
      imageSource: require("../assets/images/completitionBarImages/11.png"),
    },
    {
      id: 12,
      imageSource: require("../assets/images/completitionBarImages/12.png"),
    },
    {
      id: 13,
      imageSource: require("../assets/images/completitionBarImages/13.png"),
    },
    {
      id: 14,
      imageSource: require("../assets/images/completitionBarImages/14.png"),
    },
    {
      id: 15,
      imageSource: require("../assets/images/completitionBarImages/15.png"),
    },
    {
      id: 16,
      imageSource: require("../assets/images/completitionBarImages/16.png"),
    },
    {
      id: 17,
      imageSource: require("../assets/images/completitionBarImages/17.png"),
    },
    {
      id: 18,
      imageSource: require("../assets/images/completitionBarImages/18.png"),
    },
    {
      id: 19,
      imageSource: require("../assets/images/completitionBarImages/19.png"),
    },
  ]);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          initialSlide: 2,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };
  return (
    <div className="completion-bar container my-5">
      {/* <div className="row">
        <div className="col-md-6">
          <div className="progressStylingFigma">
            <p className="font-face-Montserrat-Regular">Figma</p>
            <ProgressBar
              completed={"90"}
              barContainerClassName="containerProgressFigma"
              completedClassName="barCompletedProgressFigma"
              labelClassName="labelProgressFigma"
            />
          </div>

          <div className="progressStylingPhotoshop">
            <p className="font-face-Montserrat-Regular">Photoshop</p>
            <ProgressBar
              completed={"66"}
              barContainerClassName="containerProgressPhotoshop"
              completedClassName="barCompletedProgressPhotoshop"
              labelClassName="labelProgressPhotoshop"
            />
          </div>

          <div className="progressStylingXD">
            <p className="font-face-Montserrat-Regular">Adobe XD</p>
            <ProgressBar
              completed={"95"}
              barContainerClassName="containerProgressXD"
              completedClassName="barCompletedProgressXD"
              labelClassName="labelProgressXD"
            />
          </div>
        </div>

        <div className="col-md-6">
          <div className="progressStylingJavaScript">
            <p className="font-face-Montserrat-Regular">JavaScript</p>
            <ProgressBar
              completed={"90"}
              barContainerClassName="containerProgressJavaScript"
              completedClassName="barCompletedProgressJavaScript"
              labelClassName="labelProgressJavaScript"
            />
          </div>

          <div className="progressStylingGolang">
            <p className="font-face-Montserrat-Regular">Golang</p>
            <ProgressBar
              completed={"80"}
              barContainerClassName="containerProgressGolang"
              completedClassName="barCompletedProgressGolang"
              labelClassName="labelProgressGolang"
            />
          </div>

          <div className="progressStylingRN">
            <p className="font-face-Montserrat-Regular">React Native</p>
            <ProgressBar
              completed={"90"}
              barContainerClassName="containerProgressRN"
              completedClassName="barCompletedProgressRN"
              labelClassName="labelProgressRN"
            />
          </div>
        </div>
      </div> */}

      <Slider {...settings}>
        {elements?.map((x, index) => (
          <div className="px-3">
            <img
              className="img-fluid"
              src={x.imageSource}
              alt={`img-${index}`}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default CompletionBar;
