import React, { useState } from "react";
import "./ContactForm.css";
import horizontalLine from "../assets/images/contactFormImages/contactLine.png";
import { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { useNavigate, useSearchParams } from "react-router-dom";

const ContactForm = () => {
  const [show, setShow] = useState(false);
  const [searchparams] = useSearchParams();

  const [subject, setSubject] = useState("");

  let pricingPackage = "";
  if (searchparams.has("packageName") && searchparams.has("packagePrice")) {
    pricingPackage =
      searchparams.get("packageName") +
      " (" +
      searchparams.get("packagePrice") +
      ")";
  }

  useEffect(() => {
    setSubject(pricingPackage || "");
  }, [pricingPackage]);

  const handleClick = () => {
    setShow(!show);
  };

  useEffect(() => {
    Aos.init({ duration: 1000 });
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const contactData = [
    {
      icon: require("../assets/images/contactFormImages/Group 93.png"),
      text: "hello@mobdev.com",
    },
    {
      icon: require("../assets/images/contactFormImages/Group 94.png"),
      text: "(001) 8234 2321",
    },
    {
      icon: require("../assets/images/contactFormImages/Group 95.png"),
      text: "hello@mobdev.com",
    },
  ];
  return (
    <>
      <div className="contact-form text-white">
        <div class="container py-5">
          <div>
            <h3
              className="font-face-Montserrat-Semi-Bold"
              data-aos="fade-right"
              data-aos-duration="1300"
            >
              CONTACT US
            </h3>
            <img src={horizontalLine} alt="hr" />
          </div>
          <div class="row py-5">
            <div class="col-sm-4 py-4">
              <h3
                className="font-face-Montserrat-Semi-Bold"
                data-aos="fade-right"
                data-aos-duration="1300"
              >
                GET IN TOUCH
              </h3>
              <img src={horizontalLine} alt="hr" />
              <p
                className="font-face-Montserrat-Regular"
                data-aos="fade-right"
                data-aos-duration="1300"
              >
                Lorem ipsum dolor sit amet, consectetur
                <span className="d-lg-block">
                  adipiscing elit. Ut elit tellus, luctus nec
                </span>
                ullamcorper mattis, pulvinar dapibus leo.
              </p>
            </div>
            <div class="col-sm-8 py-4 contact-form-inner">
              <form id="contact" method="post" class="form" role="form">
                <div class="row">
                  <div class="col-xs-6 col-md-6 form-group">
                    <div class="form-group">
                      <label className="font-face-Montserrat-Light">Name</label>
                      <input
                        className="form-control font-face-Montserrat-Light"
                        id="name"
                        name="name"
                        type="text"
                        placeholder="Enter your name"
                      />
                    </div>
                  </div>
                  <div class="col-xs-6 col-md-6 form-group">
                    <label className="font-face-Montserrat-Light">Email</label>
                    <input
                      class="form-control font-face-Montserrat-Light"
                      id="email"
                      name="email"
                      type="email"
                      placeholder="Enter your email"
                    />
                  </div>

                  <div class="col-xs-6 col-md-6 form-group">
                    <label className="font-face-Montserrat-Light">
                      Phone Number
                    </label>
                    <input
                      class="form-control font-face-Montserrat-Light"
                      id="phone"
                      name="phone"
                      type="text"
                      placeholder="Enter your phone number"
                    />
                  </div>

                  <div class="col-xs-6 col-md-6 form-group">
                    <label className="font-face-Montserrat-Light">
                      Subject
                    </label>
                    <input
                      class="form-control font-face-Montserrat-Light"
                      id="subject"
                      name="subject"
                      type="text"
                      placeholder="Enter Subject"
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                    />
                  </div>

                  {/* -----CHECKBOXES----- */}
                  <div className="checkbox-main">
                    <div className="font-face-Montserrat-Light my-3">
                      SERVICES THAT ARE YOU INTERESTED
                    </div>
                    <div className="row py-2">
                      <div class="col-md-6 form-group">
                        <input type="checkbox" />
                        <label
                          htmlFor="myCheckbox"
                          className="font-face-Montserrat-Light ms-3"
                        >
                          STATIONERY DESIGNING
                        </label>
                      </div>
                      <div class="col-md-6 form-group">
                        <input type="checkbox" />
                        <label className="font-face-Montserrat-Light ms-3">
                          LOGO DESIGNING
                        </label>
                      </div>
                    </div>

                    <div className="row py-2">
                      <div class="col-md-6 form-group">
                        <input type="checkbox" />
                        <label className="font-face-Montserrat-Light ms-3">
                          BRANDING & MARKETING
                        </label>
                      </div>
                      <div class="col-md-6 form-group">
                        <input type="checkbox" />
                        <label className="font-face-Montserrat-Light ms-3">
                          UI/UX DESIGNING
                        </label>
                      </div>
                    </div>

                    <div className="row py-2">
                      <div class="col-md-6 form-group">
                        <input type="checkbox" />
                        <label className="font-face-Montserrat-Light ms-3">
                          WEBSITE DESIGNING
                        </label>
                      </div>
                      <div class="col-md-6 form-group">
                        <input type="checkbox" />
                        <label className="font-face-Montserrat-Light ms-3">
                          MOBILE APP DEVELOPMENT
                        </label>
                      </div>
                    </div>

                    <div className="row py-2">
                      <div class="col-md-6 form-group">
                        <input type="checkbox" onClick={handleClick} />
                        <label className="font-face-Montserrat-Light ms-3">
                          OTHER
                        </label>
                      </div>
                    </div>

                    {show ? (
                      <div className="row py-2">
                        <div class="col-lg-6 form-group">
                          <input
                            class="form-control mt-2 font-face-Montserrat-Light"
                            id="Other-main"
                            type="text"
                            placeholder="Other please specify"
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <div class="col-lg-12 col-xs-6 col-md-12 form-group py-3">
                    <label className="font-face-Montserrat-Light">
                      Message
                    </label>
                    <textarea
                      class="form-control font-face-Montserrat-Light"
                      id="message"
                      name="message"
                      rows="5"
                    />
                  </div>

                  <br />
                  <div class="row">
                    <div class="col-xs-12 col-md-12 form-group py-4">
                      <button
                        class="font-face-Montserrat-Regular contact-btn"
                        type="submit"
                      >
                        Send Message
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div className="row px-lg-2 py-5 text-center">
            {contactData.map((x, index) => {
              return (
                <div
                  key={index}
                  className="col-lg-4 mb-4"
                  data-aos="zoom-in"
                  data-aos-duration="1300"
                >
                  <div className="contact-icons-inner py-4 px-5">
                    <img src={x.icon} alt="msg" />
                    <p className="font-face-Montserrat-Regular pt-2 m-0">
                      {x.text}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactForm;
