import React, { useEffect, useState } from "react";
import horizontalLine from "../assets/images/pricingImages/Line 1.png";
import buttonIcon from "../assets/images/selectedWorkSectionImages/rightTickrRedArrow.png";
import selectedRightImage from "../assets/images/selectedWorkSectionImages/MaskGroup2.webp";
import selectedRightImage2 from "../assets/images/selectedWorkSectionImages/Mask Group.webp";
import "../components/SelectedWorkSection.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";

const SelectedWork = () => {
  const navigate = useNavigate();
  useEffect(() => {
    Aos.init({ duration: 1000 });
  });

  const goToService = () => {
    navigate('/our-services')
  }
  return (
    <div className="selected-work text-white">
      <div className="container text-center py-5">
        <h3
          className="font-face-Montserrat-Semi-Bold"
          data-aos="zoom-in"
          data-aos-duration="900"
        >
          SELECTED WORK
        </h3>
        <img src={horizontalLine} alt="hr" />
        <p
          className="font-face-Montserrat-Regular"
          data-aos="zoom-in"
          data-aos-duration="900"
        >
          <span className="d-lg-block">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
            tellus,{" "}
          </span>
          luctus nec ullamcorper mattis, pulvinar dapibus leo.
        </p>

        <div className="row px-lg-5 py-5">
          <div className="col-lg-6 col-md-6 d-flex flex-column align-items-start justify-content-center">
            <p className="font-face-Montserrat-Medium selected-heading m-0">
              Greetme.
            </p>
            <p className="font-face-Montserrat-Regular selected-description">
              GreetMe app streamlines your workday, empowering <span className="d-lg-block">professionals to efficiently manage their schedules and</span> priorities with precision and ease.
            </p>
            <div onClick={() => window.open("https://xd.adobe.com/view/977c203c-8c1b-474a-a4c4-8efb862b2a65-c22f/")} className="font-face-Montserrat-Medium selected-btn">
              Click to View
              <img className="ms-2" src={buttonIcon} alt="btn-logo" />
            </div>
          </div>

          <div className="col-lg-6 col-md-6">
            <img
              className="img-fluid"
              src={selectedRightImage}
              alt="selected-right"
            />
          </div>
        </div>

        {/* -------SECOND2------------ */}
        <div className="row px-lg-5 py-5">
          <div className="col-lg-6 col-md-6">
            <img
              className="img-fluid"
              src={selectedRightImage2}
              alt="selected-right"
            />
          </div>

          <div className="col-lg-6 col-md-6 d-flex flex-column align-items-start justify-content-center px-lg-5">
            <p className="font-face-Montserrat-Medium selected-heading m-0">
              Voklogik
            </p>
            <p className="font-face-Montserrat-Regular selected-description">
              Streamline your logistics management with Voklogik, the <span className="d-lg-block">app that simplifies data handling, enabling businesses to</span> make informed decisions and enhance their bottom line with ease.
            </p>
            <div onClick={() => window.open("https://xd.adobe.com/view/57e1f3a1-1722-4996-a48f-93673c7e8c45-02b2/")} className="font-face-Montserrat-Medium selected-btn">
              Click to View
              <img className="ms-2" src={buttonIcon} alt="btn-logo" />
            </div>
          </div>
        </div>

        <button onClick={goToService} className="font-face-Montserrat-Regular selected-more-btn">
          View More Projects
        </button>
      </div>
    </div>
  );
};

export default SelectedWork;
