import React, { useState } from "react";
import "./NavigationBar.css";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Button, Container, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import webLogo from "../assets/images/navbarImages/logo.png";
import stationaryDesignLogo from "../assets/images/navbarImages/Group 102.png";
import logoDesignLogo from "../assets/images/navbarImages/Group 124.png";
import uiUxLogo from "../assets/images/navbarImages/Group 104.png";
import webDesignLogo from "../assets/images/navbarImages/webDesigningLogo.png";
import BrandAndMarketingLogo from "../assets/images/navbarImages/BrandAndMarketingLogo.png";
import MobileAppLogo from "../assets/images/navbarImages/MobileAppDevelopmentLogo.png";

const NavigationBar = () => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);

  const handleNavItemClick = () => {
    setExpanded(false);
  };

  const goToLogoDesign = () => {
    window.open("/logo-design", "_blank")
    // navigate("/logo-design");
  };

  const goToStationery = () => {
    window.open("/stationery-design", "_blank")
    // navigate("/stationery-design");
  };

  const goToUiUx = () => {
    window.open("/uiux-design", "_blank")
    // navigate("/uiux-design");
  };

  const goToWebsiteDesigning = () => {
    window.open("/website-design", "_blank")
    // navigate('/website-design')
  }

  const goToMarketingAndBranding = () => {
    window.open("/marketing", "_blank")
    // navigate('/marketing')
  }

  const goToMobileAppDevelopment = () => {
    window.open("/mobile-app-development", "_blank")
    // navigate('/mobile-app-development')
  }

  const goToContact = () => {
    navigate("/contact");
  };

  const goToHome = () => {
    navigate("/");
  };

  const goToService = () => {
    navigate("/our-services");
  };

  const goToAboutPage = () => {
    navigate("/about-us");
  };

  return (
    <Navbar expand="lg" expanded={expanded} onToggle={() => setExpanded(!expanded)}>
      <Container>
        <Navbar.Brand onClick={() => { handleNavItemClick(); goToHome(); }}>
          <img className="brand-img" src={webLogo} alt="web-logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="ms-auto my-2 my-lg-0"
            style={{ maxHeight: "100px" }}
            navbarScroll
          >
            <Nav.Link className="font-face-Montserrat-Regular" onClick={() => { handleNavItemClick(); goToHome(); }}>Home</Nav.Link>
            <Nav.Link className="font-face-Montserrat-Regular" onClick={() => { handleNavItemClick(); goToAboutPage(); }}>About</Nav.Link>
            <NavDropdown title="Portfolio" id="navbarScrollingDropdown">
              <NavDropdown.Item className="font-face-Montserrat-Medium" onClick={() => { handleNavItemClick(); goToStationery(); }}>
                <img style={{ marginRight: '10px' }} src={stationaryDesignLogo} alt="logo" />
                Stationary Designing
              </NavDropdown.Item>
              <NavDropdown.Item className="font-face-Montserrat-Medium" onClick={() => { handleNavItemClick(); goToLogoDesign(); }}>
                <img style={{ marginRight: '10px' }} src={logoDesignLogo} alt="logo" />
                Logo Designing
              </NavDropdown.Item>
              <NavDropdown.Item className="font-face-Montserrat-Medium" onClick={() => { handleNavItemClick(); goToUiUx(); }}>
                <img style={{ marginRight: '10px' }} src={uiUxLogo} alt="logo" />
                UI/UX Designing
              </NavDropdown.Item>
              <NavDropdown.Item className="font-face-Montserrat-Medium" onClick={() => { handleNavItemClick(); goToWebsiteDesigning(); }}>
                <img style={{ marginRight: '10px' }} src={webDesignLogo} alt="logo" />
                Website Designing
              </NavDropdown.Item>
              <NavDropdown.Item className="font-face-Montserrat-Medium" onClick={() => { handleNavItemClick(); goToMarketingAndBranding(); }}>
                <img style={{ marginRight: '10px' }} src={BrandAndMarketingLogo} alt="logo" />
                Branding & Marketing
              </NavDropdown.Item>
              <NavDropdown.Item className="font-face-Montserrat-Medium" onClick={() => { handleNavItemClick(); goToMobileAppDevelopment(); }}>
                <img style={{ marginRight: '10px' }} src={MobileAppLogo} alt="logo" />
                Mobile App Development
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link className="font-face-Montserrat-Regular" onClick={() => { handleNavItemClick(); goToService(); }}>Services</Nav.Link>
            <Nav.Link className="font-face-Montserrat-Regular" onClick={() => { handleNavItemClick(); goToContact(); }}>Contact Us</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavigationBar;
