import React, { useEffect } from "react";
import "../components/ServiceOfferings.css";
import horizontalLine from "../assets/images/pricingImages/Line 1.png";
import searchLogo from "../assets/images/serviceOfferings/Group 89.png";
import brandingLogo from "../assets/images/serviceOfferings/Group 90.png";
import uiUxLogo from "../assets/images/serviceOfferings/Group 91.png";
import appDevLogo from "../assets/images/serviceOfferings/Group 92.png";
import buttonIcon from "../assets/images/pricingImages/Frame.png";
import Aos from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";

const ServiceOfferings = () => {
  const navigate = useNavigate();
  useEffect(() => {
    Aos.init({ duration: 1000 });
  });

  const goToWebSiteDesigning = () => {
    navigate('/website-design')
  }
  const goToBranding = () => {
    navigate('/marketing')
  }
  const goToUiUx = () => {
    navigate('/uiux-design')
  }
  const goToMobileApp = () => {
    navigate('/mobile-app-development')
  }

  return (
    <div className="service-main text-white">
      <div className="container py-5">
        <div className="row">
          <div className="col-lg-4">
            <div data-aos="fade-right" data-aos-duration="1200">
              <h3 className="font-face-Montserrat-Semi-Bold">SERVICES</h3>
              <img src={horizontalLine} alt="hr" />
              <p className="font-face-Montserrat-Regular">
                Trust in our design and development <span className="d-lg-block">prowess to transform your vision into</span> <span className="d-lg-block">reality, delivering captivating designs and</span> <span className="d-lg-block">seamless functionality that set your</span> business apart.
              </p>
            </div>
          </div>

          <div className="col-lg-8">
            <div className="row px-3 px-lg-3 px-md-1">
              <div
                className="col-lg-6 col-md-6 px-2 mb-4"
                data-aos="zoom-in"
                data-aos-duration="1200"
              >
                <div>
                  <img src={searchLogo} alt="search" />
                  <h5 className="font-face-Montserrat-Medium service-main-heading pt-3 pb-0">
                    Website Designing
                  </h5>
                  <p className="font-face-Montserrat-Regular">
                    Elevate your online presence with our expertly crafted website designs, meticulously tailored <span className="d-lg-block">to showcase your unique brand identity and</span> engage your target audience.
                  </p>
                  <button onClick={goToWebSiteDesigning} className="font-face-Montserrat-Medium service-btn">
                    learn More
                    <img className="ms-2" src={buttonIcon} alt="right-icon" />
                  </button>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 px-2 mb-4"
                data-aos="zoom-in"
                data-aos-duration="1200"
              >
                <div>
                  <img src={brandingLogo} alt="search" />
                  <h5 className="font-face-Montserrat-Medium service-main-heading pt-3 pb-0">
                    Designing & Branding
                  </h5>
                  <p className="font-face-Montserrat-Regular">
                    With a keen eye for detail and a passion for creativity, we transform your brand into a compelling narrative, leaving an indelible mark in the hearts and minds of your customers.
                  </p>
                  <button onClick={goToBranding} className="font-face-Montserrat-Medium service-btn">
                    learn More
                    <img className="ms-2" src={buttonIcon} alt="right-icon" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-4">
            <div></div>
          </div>

          <div className="col-lg-8">
            <div className="row px-3">
              <div
                className="col-lg-6 col-md-6 px-2 mb-4"
                data-aos="zoom-in"
                data-aos-duration="1200"
              >
                <div>
                  <img src={uiUxLogo} alt="search" />
                  <h5 className="font-face-Montserrat-Medium service-main-heading pt-3 pb-0">
                    UI / UX Design
                  </h5>
                  <p className="font-face-Montserrat-Regular">
                    Our UI/UX design expertise goes beyond <span className="d-lg-block">aesthetics, focusing on intuitive user</span> <span className="d-lg-block">experiences that captivate, engage, and drive</span> user loyalty
                  </p>
                  <button onClick={goToUiUx} className="font-face-Montserrat-Medium service-btn">
                    learn More
                    <img className="ms-2" src={buttonIcon} alt="right-icon" />
                  </button>
                </div>
              </div>

              <div
                className="col-lg-6 col-md-6 px-2 mb-4"
                data-aos="zoom-in"
                data-aos-duration="1200"
              >
                <div>
                  <img src={appDevLogo} alt="search" />
                  <h5 className="font-face-Montserrat-Medium service-main-heading pt-3 pb-0">
                    App Development
                  </h5>
                  <p className="font-face-Montserrat-Regular">
                    Our mobile app development team <span className="d-lg-block">specializes in turning your ideas into feature-</span>rich, user-friendly applications that thrive on both iOS and Android platforms.
                  </p>
                  <button onClick={goToMobileApp} className="font-face-Montserrat-Medium service-btn">
                    learn More
                    <img className="ms-2" src={buttonIcon} alt="right-icon" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceOfferings;
