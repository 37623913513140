import React, { useEffect } from "react";

import horizontalLine from "../assets/images/pricingImages/Line 1.png";

const PortfolioBanner = (props) => {
  const BannerData = props.BannerData;

  return (
    <>
      <div
        className="portfolio-banner text-white"
        style={{
          backgroundImage: `url(${BannerData.bannerImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          minHeight: "300px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="container py-4">
          <h1 className="font-face-Montserrat-Semi-Bold">
            {BannerData.bannerHeading}
          </h1>
          <img src={horizontalLine} alt="hr" />
          <p className="font-face-Montserrat-Medium portfolio-paragraph">
            {BannerData.bannerParaOne}
            <span className="d-lg-block portfolio-paragraph">
              {BannerData.bannerParaTwo}
            </span>
            {BannerData.bannerParaThree}

            {BannerData.bannerParaFour ? (
              <span className="d-lg-block portfolio-paragraph">
                {BannerData.bannerParaFour}
              </span>
            ) : null}
          </p>
        </div>
      </div>
    </>
  );
};

export default PortfolioBanner;
