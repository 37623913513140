import React, { useEffect } from "react";
import horizontalLine from "../assets/images/pricingImages/Line 1.png";
import { useState } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { createSearchParams, useNavigate } from "react-router-dom";

const PricingCards = () => {
  const navigate = useNavigate();
  useEffect(() => {
    Aos.init({ duration: 1000 });
  });

  const goToContact = (x, y) => {
    let packageName = x;
    let packagePrice = y;
    navigate({
      pathname: "/contact",
      search: createSearchParams({
        packageName,
        packagePrice,
      }).toString(),
    });
  };

  const [pricingData, setPricingData] = useState([
    {
      id: 1,
      heading: "Newbie Package",
      redIconImage: require("../assets/images/pricingImages/Group86.png"),
      priceText: "$49.99",
      subHeading1: "Basic Logo Design :",
      listItem1:
        "Simple and clean design suitable for startups for individuals.",
      listItem2: "Limited revisions (2-3 rounds).",
      subHeading2: "File Formats :",
      listItem3: "JPEG and PNG formats.",
      subHeading3: "Delivery Time :",
      listItem4: "24-48 Hours",
      buttonText: "Select Plan",
      buttonIcon: require("../assets/images/pricingImages/Frame.png"),
    },
    {
      id: 2,
      heading: "Professional Identity",
      redIconImage: require("../assets/images/pricingImages/Group86.png"),
      priceText: "$199.99",
      subHeading1: "Custom Logo Design :",
      listItem1: "Unique and professional logo design.",
      listItem2: "Moderate revisions (4-6 rounds).",
      subHeading2: "File Formats :",
      listItem3:
        "Includes vector files (AI, EPS) and raster files (JPEG, PNG).",
      subHeading3: "Brand Guide :",
      listItem4: "Basic brand guidelines for logo usage.",
      subHeading4: "Delivery Time:",
      listItem5: "24-48 Hours",
      buttonText: "Select Plan",
      buttonIcon: require("../assets/images/pricingImages/Frame.png"),
    },
    {
      id: 3,
      heading: "Premium Branding",
      redIconImage: require("../assets/images/pricingImages/Group86.png"),
      priceText: "$399.99",
      subHeading1: "Bespoke Logo Design :",
      listItem1: "Highly creative and tailored logo design.",
      listItem2: "Unlimited revisions.",
      subHeading2: "Comprehensive File Formats :",
      listItem3: "Vector files (AI, EPS, SVG) and raster files (JPEG, PNG).",
      subHeading3: "Brand Style Guide :",
      listItem4:
        "Detailed brand guidelines including color palette, typography, and logo usage.",
      subHeading4: "Stationery Design :",
      listItem5: "Business card and letterhead design.",
      subHeading5: "Social Media Kit :",
      listItem6:
        "Customized profile and cover images for social media platforms.",
      subHeading6: "Priority Support :",
      listItem7: "Dedicated Designer and Support",
      subHeading7: "Delivery Time :",
      listItem8: "7 to 8 Days Turnaround",
      buttonText: "Select Plan",
      buttonIcon: require("../assets/images/pricingImages/Frame.png"),
    },
  ]);
  return (
    <div className="pricing-cards text-white">
      <div className="container py-5">
        <div className="text-center">
          <h3
            className="font-face-Montserrat-Semi-Bold"
            data-aos="zoom-in"
            data-aos-duration="900"
          >
            PRICING
          </h3>
          <img src={horizontalLine} alt="hr" />
          <p
            className="font-face-Montserrat-Regular"
            data-aos="zoom-in"
            data-aos-duration="900"
          >
            <span className="d-lg-block">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
              tellus,{" "}
            </span>
            luctus nec ullamcorper mattis, pulvinar dapibus leo.
          </p>
        </div>

        <div className="row py-5 px-lg-5">
          {pricingData.map((x, index) => {
            return (
              <div key={index} className="col-lg-4 mb-4">
                <div className="inner py-3 px-2 px-md-5">
                  <div className="text-center">
                    <h5 className="font-face-Montserrat-Medium py-3">
                      {x.heading}
                    </h5>
                    <img src={x.redIconImage} alt="red-icon" />
                    <h1 className="font-face-Montserrat-Bold py-3">
                      {x.priceText}
                    </h1>
                  </div>

                  <div className="pricing-description">
                    <span className="font-face-Montserrat-Semi-Bold">
                      {x.subHeading1}
                    </span>
                    <ul className="py-1">
                      <li className="font-face-Montserrat-Regular">
                        {x.listItem1}
                      </li>
                      <li className="font-face-Montserrat-Regular">
                        {x.listItem2}
                      </li>
                    </ul>

                    <span className="font-face-Montserrat-Semi-Bold">
                      {x.subHeading2}
                    </span>
                    <ul className="py-1">
                      <li className="font-face-Montserrat-Regular">
                        {x.listItem3}
                      </li>
                    </ul>
                    <span className="font-face-Montserrat-Semi-Bold">
                      {x.subHeading3}
                    </span>
                    <ul className="py-1">
                      <li className="font-face-Montserrat-Regular">
                        {x.listItem4}
                      </li>
                    </ul>

                    {x.subHeading4 ? (
                      <span className="font-face-Montserrat-Semi-Bold">
                        {x.subHeading4}
                      </span>
                    ) : null}

                    {x.listItem5 ? (
                      <ul className="py-1">
                        <li className="font-face-Montserrat-Regular">
                          {x.listItem5}
                        </li>
                      </ul>
                    ) : null}

                    {x.subHeading5 ? (
                      <span className="font-face-Montserrat-Semi-Bold">
                        {x.subHeading5}
                      </span>
                    ) : null}

                    {x.listItem6 ? (
                      <ul className="py-1">
                        <li className="font-face-Montserrat-Regular">
                          {x.listItem6}
                        </li>
                      </ul>
                    ) : null}

                    {x.subHeading6 ? (
                      <span className="font-face-Montserrat-Semi-Bold">
                        {x.subHeading6}
                      </span>
                    ) : null}

                    {x.listItem7 ? (
                      <ul className="py-1">
                        <li className="font-face-Montserrat-Regular">
                          {x.listItem7}
                        </li>
                      </ul>
                    ) : null}

                    {x.subHeading7 ? (
                      <span className="font-face-Montserrat-Semi-Bold">
                        {x.subHeading7}
                      </span>
                    ) : null}

                    {x.listItem8 ? (
                      <ul className="py-1">
                        <li className="font-face-Montserrat-Regular">
                          {x.listItem8}
                        </li>
                      </ul>
                    ) : null}
                    {/* <div className="d-flex align-items-start justify-content-center px-2">
                      <img src={x.tickIconImage} alt="tick-icon" />
                      <p className="font-face-Montserrat-Regular ms-3">
                        <span className="d-lg-block">{x.description1}</span>
                        {x.description2}
                      </p>
                    </div> */}
                    {/* <div className="d-flex align-items-start justify-content-center px-2">
                      <img src={x.tickIconImage} alt="tick-icon" />
                      <p className="font-face-Montserrat-Regular ms-3">
                        <span className="d-lg-block">{x.description3}</span>
                        {x.description4}
                      </p>
                    </div> */}

                    {/* <div className="d-flex align-items-start justify-content-center px-2">
                      <img src={x.tickIconImage} alt="tick-icon" />
                      <p className="font-face-Montserrat-Regular ms-3">
                        <span className="d-lg-block">{x.description5}</span>
                        {x.description6}
                      </p>
                    </div> */}
                  </div>

                  <div className="pt-4 text-center">
                    <button
                      onClick={() => goToContact(x.heading, x.priceText)}
                      className="font-face-Montserrat-Regular price-btn"
                    >
                      {x.buttonText}
                      <img
                        className="ms-2"
                        src={x.buttonIcon}
                        alt="right-icon"
                      />
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PricingCards;
