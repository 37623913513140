import React, { useEffect, useState } from "react";
import PortfolioGallery from "../components/PortfolioGallery.js";
import PortfolioBanner from "../components/PortfolioBanner.js";
import "../components/PortfolioBanner.css";
import horizontalLine from "../assets/images/pricingImages/Line 1.png";
import Aos from "aos";
import "aos/dist/aos.css";

const UIUXDesigningPage = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const BannerData = {
    bannerHeading: "UI/UX Designing",
    bannerImg: require("../assets/images/portfolioImages/uiuxBanner.jpg"),
    bannerParaOne:
      "Embark on a Voyage Through Our Esteemed UI/UX Design Portfolio: Where Ingenuity Blends",
    bannerParaTwo:
      "with Purpose to Breathe Life into Digital Experiences. Immerse Yourself in a Gallery that",
    bannerParaThree:
      "Resonates with Our Commitment to Crafting Engaging Visual Journeys. Every Interface",
    bannerParaFour:
      "Reflects Precision, Zeal, and the Ability to Forge an Unforgettable Impact.",
  };

  const [pricingData, setPricingData] = useState([
    {
      id: 1,
      heading: "Silver",
      redIconImage: require("../assets/images/pricingImages/Group86.png"),
      priceText: "$60",
      tickIconImage: require("../assets/images/pricingImages/Group14.png"),
      description1: "Lorem ipsum dolor sit amet,",
      description2: "consectetur adipiscing elit.",
      description3: "Lorem ipsum dolor sit amet,",
      description4: "consectetur adipiscing elit.",
      description5: "Lorem ipsum dolor sit amet,",
      description6: "consectetur adipiscing elit.",
      buttonText: "Select Plan",
      buttonIcon: require("../assets/images/pricingImages/Frame.png"),
    },
    {
      id: 2,
      heading: "Silver",
      redIconImage: require("../assets/images/pricingImages/Group86.png"),
      priceText: "$60",
      tickIconImage: require("../assets/images/pricingImages/Group14.png"),
      description1: "Lorem ipsum dolor sit amet,",
      description2: "consectetur adipiscing elit.",
      description3: "Lorem ipsum dolor sit amet,",
      description4: "consectetur adipiscing elit.",
      description5: "Lorem ipsum dolor sit amet,",
      description6: "consectetur adipiscing elit.",
      buttonText: "Select Plan",
      buttonIcon: require("../assets/images/pricingImages/Frame.png"),
    },
    {
      id: 3,
      heading: "Silver",
      redIconImage: require("../assets/images/pricingImages/Group86.png"),
      priceText: "$60",
      tickIconImage: require("../assets/images/pricingImages/Group14.png"),
      description1: "Lorem ipsum dolor sit amet,",
      description2: "consectetur adipiscing elit.",
      description3: "Lorem ipsum dolor sit amet,",
      description4: "consectetur adipiscing elit.",
      description5: "Lorem ipsum dolor sit amet,",
      description6: "consectetur adipiscing elit.",
      buttonText: "Select Plan",
      buttonIcon: require("../assets/images/pricingImages/Frame.png"),
    },
  ]);

  const imageData = [
    {
      src: require("../assets/images/UIUX/1.jpg"),
    },
    {
      src: require("../assets/images/UIUX/2.jpg"),
    },
    {
      src: require("../assets/images/UIUX/3.jpg"),
    },

    {
      src: require("../assets/images/UIUX/4.jpg"),
    },
    {
      src: require("../assets/images/UIUX/6.jpg"),
    },
    {
      src: require("../assets/images/UIUX/8.jpg"),
    },
    {
      src: require("../assets/images/UIUX/7.jpg"),
    },
    {
      src: require("../assets/images/UIUX/5.jpg"),
    },
  ];
  return (
    <div>
      <PortfolioBanner BannerData={BannerData} />

      <div className="portfolio-inner py-5">
        <div className="text-center text-white">
          <h3
            className="font-face-Montserrat-Semi-Bold"
            data-aos="zoom-in"
            data-aos-duration="1300"
          >
            Our UI/UX Showcase
          </h3>
          <img src={horizontalLine} alt="hr" />
          <p
            className="font-face-Montserrat-Medium m-0"
            data-aos="zoom-in"
            data-aos-duration="1300"
          >
            Enter our exclusive UI/UX design portfolio, a convergence of
            innovation and purpose. Dive into a gallery that embodies our
            <span className="d-lg-block">
              commitment to weaving captivating visual stories for brands. Each
              interface reflects meticulous craftsmanship and a deep
            </span>
            <span className="d-lg-block">
              understanding of design's power to create unforgettable user
              experiences.
            </span>
          </p>
        </div>
      </div>

      <PortfolioGallery imageData={imageData} />

      <div className="pricing-cards text-white">
        <div className="container text-center pt-5">
          <h3
            className="font-face-Montserrat-Semi-Bold"
            data-aos="zoom-in"
            data-aos-duration="900"
          >
            PRICING
          </h3>
          <img src={horizontalLine} alt="hr" />
          <p
            className="font-face-Montserrat-Regular"
            data-aos="zoom-in"
            data-aos-duration="900"
          >
            <span className="d-lg-block">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
              tellus,{" "}
            </span>
            luctus nec ullamcorper mattis, pulvinar dapibus leo.
          </p>

          <div className="row py-5 px-lg-5">
            {pricingData.map((x, index) => {
              return (
                <div key={index} className="col-lg-4 mb-4">
                  <div className="inner py-5">
                    <h5 className="font-face-Montserrat-Medium py-3">
                      {x.heading}
                    </h5>
                    <img src={x.redIconImage} alt="red-icon" />
                    <h1 className="font-face-Montserrat-Bold py-3">
                      {x.priceText}
                    </h1>
                    <div className="d-flex align-items-start justify-content-center px-2">
                      <img src={x.tickIconImage} alt="tick-icon" />
                      <p className="font-face-Montserrat-Regular ms-3">
                        <span className="d-lg-block">{x.description1}</span>
                        {x.description2}
                      </p>
                    </div>
                    <div className="d-flex align-items-start justify-content-center px-2">
                      <img src={x.tickIconImage} alt="tick-icon" />
                      <p className="font-face-Montserrat-Regular ms-3">
                        <span className="d-lg-block">{x.description3}</span>
                        {x.description4}
                      </p>
                    </div>
                    <div className="d-flex align-items-start justify-content-center px-2">
                      <img src={x.tickIconImage} alt="tick-icon" />
                      <p className="font-face-Montserrat-Regular ms-3">
                        <span className="d-lg-block">{x.description5}</span>
                        {x.description6}
                      </p>
                    </div>
                    <div className="py-3">
                      <button className="font-face-Montserrat-Regular price-btn">
                        {x.buttonText}
                        <img
                          className="ms-2"
                          src={x.buttonIcon}
                          alt="right-icon"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UIUXDesigningPage;
