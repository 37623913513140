import React, { useEffect } from "react";
import PortfolioBanner from "../components/PortfolioBanner";
import horizontalLine from "../assets/images/pricingImages/Line 1.png";
import mobile1 from "../assets/images/mobileAppDevelopmentImages/1.png";
import mobile2 from "../assets/images/mobileAppDevelopmentImages/2.png";
import Aos from "aos";
import "aos/dist/aos.css";

const MobileAppDevelopmentPage = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const BannerData = {
    bannerHeading: "Mobile Application Development",
    bannerImg: require("../assets/images/mobileAppDevelopmentImages/mainBg.png"),
    bannerParaOne:
      "Mobile application development and marketing are two distinct but closely related concepts",
    bannerParaTwo:
      "in the world of technology and digital commerce. They play crucial roles in creating and",
    bannerParaThree:
      "promoting a mobile app, building a strong user base, and ultimately ensuring its success in",
    bannerParaFour: "the competitive app market.",
  };
  return (
    <div>
      <PortfolioBanner BannerData={BannerData} />
      <div className="portfolio-inner py-5">
        <div className="text-center text-white">
          <h3
            className="font-face-Montserrat-Semi-Bold"
            data-aos="zoom-in"
            data-aos-duration="1300"
          >
            Your Proven Path to Mobile App Excellence
          </h3>
          <img src={horizontalLine} alt="hr" />
          <p
            className="font-face-Montserrat-Medium m-0"
            data-aos="zoom-in"
            data-aos-duration="1300"
          >
            "A Proven Blueprint for Crafting Exceptional Apps on iOS, Android,
            and Cross-Platform Environments. Explore the strategic
            <span className="d-lg-block">
              steps, tools, and best practices to build mobile applications that
              stand out in a competitive market. From ideation to
            </span>
            <span className="d-lg-block">
              deployment, our process ensures your app's success."
            </span>
          </p>
        </div>
      </div>
      <div className="custom-process">
        <div className="container py-5">
          <div className="row custom-process-content">
            <div className="col-lg-6">
              <div>
                <p className="font-face-Montserrat-Bold">
                  1. Idea and Conceptualization
                </p>
                <p className="font-face-Montserrat-Regular text-white">
                  Begin by identifying the purpose and goals of your mobile app.{" "}
                  <span className="d-lg-block">
                    Define your target audience and understand their needs and
                  </span>{" "}
                  <span className="d-lg-block">
                    preferences. Create a clear concept for your app, outlining
                    its
                  </span>{" "}
                  features and functionalities.
                </p>
              </div>

              <div>
                <p className="font-face-Montserrat-Bold">2. Market Research</p>
                <p className="font-face-Montserrat-Regular text-white">
                  Conduct thorough market research to understand the{" "}
                  <span className="d-lg-block">
                    competition and trends in your app's niche. Identify gaps
                    and
                  </span>{" "}
                  opportunities that your app can address.
                </p>
              </div>

              <div>
                <p className="font-face-Montserrat-Bold">
                  3. Design and Wireframing
                </p>
                <p className="font-face-Montserrat-Regular text-white">
                  Create wireframes and prototypes to visualize the app's layout{" "}
                  <span className="d-lg-block">
                    and user interface (UI). Design the app's user experience
                    (UX)
                  </span>{" "}
                  to ensure it is intuitive and user-friendly.
                </p>
              </div>

              <div>
                <p className="font-face-Montserrat-Bold">
                  4. Development Environment Setup
                </p>
                <p className="font-face-Montserrat-Regular text-white">
                  Install and configure the necessary tools and development{" "}
                  <span className="d-lg-block">
                    environments for React Native, such as Node.js npm (Node
                  </span>
                  Package Manager) and the React Native CLI (Command Line{" "}
                  <span className="d-lg-block">Interface).</span>
                </p>
              </div>

              <div>
                <p className="font-face-Montserrat-Bold">
                  5. Coding and Development
                </p>
                <p className="font-face-Montserrat-Regular text-white">
                  Write the code for your mobile app using React Native
                  <span className="d-lg-block">
                    components and JavaScript. React Native allows for cross-
                  </span>
                  platform development, meaning you can write one codebase{" "}
                  <span className="d-lg-block">
                    that works on both iOS and Android platforms.
                  </span>
                </p>
              </div>

              <div>
                <p className="font-face-Montserrat-Bold">6. Testing</p>
                <p className="font-face-Montserrat-Regular text-white">
                  Rigorously test your app to identify and fix bugs and ensure{" "}
                  <span className="d-lg-block">
                    its stability and performance. You can use various testing
                  </span>{" "}
                  frameworks and tools, such as Jest for unit testing and tools{" "}
                  <span className="d-lg-block">
                    like Appium or Detox for end-to-end testing.
                  </span>
                </p>
              </div>
            </div>
            <div className="col-lg-6 d-flex flex-column align-items-center justify-content-center">
              <img className="img-fluid" src={mobile1} alt="1" />
            </div>
          </div>

          <div className="row custom-process-content-reverse mt-5">
            <div className="col-lg-6 d-flex flex-column align-items-center justify-content-center px-lg-5">
              <img className="img-fluid" src={mobile2} alt="1" />
            </div>
            <div className="col-lg-6">
              <div>
                <p className="font-face-Montserrat-Bold">7. Integration</p>
                <p className="font-face-Montserrat-Regular text-white">
                  Integrate third-party libraries, APIs, and services as needed
                  to{" "}
                  <span className="d-lg-block">
                    enhance the app's functionality. This can include adding
                  </span>{" "}
                  features like authentication, payment processing, and social{" "}
                  <span className="d-lg-block">media sharing.</span>
                </p>
              </div>

              <div>
                <p className="font-face-Montserrat-Bold">8. Optimization</p>
                <p className="font-face-Montserrat-Regular text-white">
                  Optimize your app for performance and responsiveness. Use{" "}
                  <span className="d-lg-block">
                    tools like the React Native Performance Monitor to identify
                  </span>{" "}
                  and address any bottlenecks or issues.
                </p>
              </div>

              <div>
                <p className="font-face-Montserrat-Bold">9. UI/UX Refinement</p>
                <p className="font-face-Montserrat-Regular text-white">
                  Continuously refine the app's UI/UX based on user feedback{" "}
                  <span className="d-lg-block">
                    and usability testing. Ensure that the app meets design
                  </span>{" "}
                  guidelines and standards for both iOS and Android platforms.
                </p>
              </div>

              <div>
                <p className="font-face-Montserrat-Bold">10. Security</p>
                <p className="font-face-Montserrat-Regular text-white">
                  Implement security measures to protect user data and the{" "}
                  <span className="d-lg-block">
                    app itself. Use best practices for data encryption, user
                  </span>
                  authentication, and secure API communication.
                </p>
              </div>

              <div>
                <p className="font-face-Montserrat-Bold">11. Deployment</p>
                <p className="font-face-Montserrat-Regular text-white">
                  Prepare your app for deployment by generating platform -
                  <span className="d-lg-block">
                    specific binary files. For iOS, you'll create an IPA file,
                    and for
                  </span>{" "}
                  Android, you'll generate an APK file.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileAppDevelopmentPage;
