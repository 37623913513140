import React from "react";
import brandLogo from "../assets/images/brandsGoalsImages/brandLogo.png";
import logo from "../assets/images/brandsGoalsImages/Logo 1.png";

const BrandGoals = () => {
  return (
    <>
      <div className="brands-goals-first">
        <div className="brands-goals-first-inner">
          <div className="container-fluid text-white">
            <div className="row">
              <div className="col-md-6 px-lg-5">
                <img className="img-fluid" src={brandLogo} alt="brandLogo" />
                <h2 className="font-face-Montserrat-Bold mt-5">
                  Elevating Your Digital Presence with{" "}
                  <span className="d-lg-block">
                    {" "}
                    Innovative Design and Development
                  </span>{" "}
                  Solutions
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="brands-goals-second py-4 text-white">
        <div className="brands-goals-second-inner">
          <h2 className="font-face-Montserrat-Bold">
            Transforming Visions Into{" "}
            <span className="d-block">Digital Masterpieces</span>
          </h2>
        </div>

        <div className="container-fluid py-5">
          <div className="row">
            <div className="col-md-6 px-lg-5">
              <p className="font-face-Montserrat-Medium">
                At Patronus Pixels, we are more than just a design and
                development agency{" "}
                <span className="d-lg-block">
                  we are your creative partners in the digital realm. Our
                  commitment to excellence,
                </span>{" "}
                <span className="d-lg-block">
                  innovation, and client satisfaction sets us apart in the
                  dynamic world of design,
                </span>
                website development, and mobile application creation. With a
                relentless passion{" "}
                <span className="d-lg-block">
                  for turning ideas into reality, Patronus Pixels is your
                  gateway to a visually stunning
                </span>{" "}
                and seamlessly functional digital presence.
              </p>
            </div>
            <div className="col-md-6 d-flex justify-content-end">
              <img className="img-fluid" src={logo} alt="logo1" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BrandGoals;
