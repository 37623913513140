import React, { useEffect } from "react";
import "./HeroImage.css";
import sideLogo from "../assets/images/heroImages/Group 4.png";
import { useNavigate } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";

const HeroImage = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  });
  const navigate = useNavigate();

  const goToContact = () => {
    navigate("/contact");
  };

  // const goToBrands = () => {
  //   window.open("/brand-goals", "_blank");
  // };
  return (
    <div className="hero-image text-white">
      <div className="hero-right">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 hero-left-main">
              <img className="left-icon img-fluid" src={sideLogo} alt="logo" />
              <div className="px-3 px-sm-0 py-5 py-sm-0">
                <p
                  className="font-face-Montserrat-Medium hero-image-heading pt-3"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  Hi, I’m Maisum Abbas
                </p>
                <h1
                  className="font-face-Montserrat-Semi-Bold"
                  data-aos="fade-right"
                  data-aos-duration="1300"
                >
                  UI/UX & Graphics <span className="d-lg-block">Designer</span>
                </h1>
                <p
                  className="font-face-Montserrat-Regular"
                  data-aos="fade-right"
                  data-aos-duration="1400"
                >
                  As a seasoned UI/UX and Graphics designer, I bring a touch of
                  <span className="d-lg-block">
                    brilliance to every project. With a deep understanding of
                    user
                  </span>
                  <span className="d-lg-block">
                    behavior and a keen eye for aesthetics, I craft immersive
                  </span>
                  experiences that captivate and engage. Whether it's designing
                  <span className="d-lg-block">
                    intuitive interfaces or creating stunning visuals, My
                    expertise
                  </span>{" "}
                  will elevate your mobile and web applications to new heights.
                  <span className="d-lg-block">
                    Embrace a seamless blend of functionality and artistry with
                    me
                  </span>{" "}
                  as your design partner.
                </p>
                <div className="hero-button-main">
                  <button
                    className="font-face-Montserrat-Regular contact-me-btn mt-3"
                    data-aos="zoom-in"
                    data-aos-duration="1000"
                    onClick={goToContact}
                  >
                    Contact Us
                  </button>

                  {/* <button
                    onClick={goToBrands}
                    className="font-face-Montserrat-Regular detail-me-btn mt-3"
                    data-aos="zoom-in"
                    data-aos-duration="1000"
                  >
                    Brand Goals
                  </button> */}
                </div>
              </div>
            </div>
            <div className="col-lg-6"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroImage;
