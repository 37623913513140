import React, { useEffect, useState } from "react";
import PortfolioGallery from "../components/PortfolioGallery.js";
import PortfolioBanner from "../components/PortfolioBanner.js";
import "../components/PortfolioBanner.css";
import horizontalLine from "../assets/images/pricingImages/Line 1.png";
import Aos from "aos";
import "aos/dist/aos.css";

const LogoDesigningPage = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  });

  const BannerData = {
    bannerHeading: "Logo Designing",
    bannerImg: require("../assets/images/portfolioImages/logoBanner.jpg"),
    bannerParaOne:
      "Elevate your brand with a logo that's not just a symbol, but a story. Our designs intertwine",
    bannerParaTwo:
      "uniqueness and allure, capturing the essence of your business in every curve and color.",
    bannerParaThree:
      "Stand out, be remembered, and make your mark with a logo that resonates.",
  };

  const [pricingData, setPricingData] = useState([
    {
      id: 1,
      heading: "Silver",
      redIconImage: require("../assets/images/pricingImages/Group86.png"),
      priceText: "$60",
      tickIconImage: require("../assets/images/pricingImages/Group14.png"),
      description1: "Lorem ipsum dolor sit amet,",
      description2: "consectetur adipiscing elit.",
      description3: "Lorem ipsum dolor sit amet,",
      description4: "consectetur adipiscing elit.",
      description5: "Lorem ipsum dolor sit amet,",
      description6: "consectetur adipiscing elit.",
      buttonText: "Select Plan",
      buttonIcon: require("../assets/images/pricingImages/Frame.png"),
    },
    {
      id: 2,
      heading: "Silver",
      redIconImage: require("../assets/images/pricingImages/Group86.png"),
      priceText: "$60",
      tickIconImage: require("../assets/images/pricingImages/Group14.png"),
      description1: "Lorem ipsum dolor sit amet,",
      description2: "consectetur adipiscing elit.",
      description3: "Lorem ipsum dolor sit amet,",
      description4: "consectetur adipiscing elit.",
      description5: "Lorem ipsum dolor sit amet,",
      description6: "consectetur adipiscing elit.",
      buttonText: "Select Plan",
      buttonIcon: require("../assets/images/pricingImages/Frame.png"),
    },
    {
      id: 3,
      heading: "Silver",
      redIconImage: require("../assets/images/pricingImages/Group86.png"),
      priceText: "$60",
      tickIconImage: require("../assets/images/pricingImages/Group14.png"),
      description1: "Lorem ipsum dolor sit amet,",
      description2: "consectetur adipiscing elit.",
      description3: "Lorem ipsum dolor sit amet,",
      description4: "consectetur adipiscing elit.",
      description5: "Lorem ipsum dolor sit amet,",
      description6: "consectetur adipiscing elit.",
      buttonText: "Select Plan",
      buttonIcon: require("../assets/images/pricingImages/Frame.png"),
    },
  ]);

  const imageData = [
    {
      src: require("../assets/images/logoDesignImages/1.jpg"),
    },
    {
      src: require("../assets/images/logoDesignImages/2.jpg"),
    },
    {
      src: require("../assets/images/logoDesignImages/3.jpg"),
    },

    {
      src: require("../assets/images/logoDesignImages/4.jpg"),
    },
    {
      src: require("../assets/images/logoDesignImages/8.jpg"),
    },
    {
      src: require("../assets/images/logoDesignImages/6.jpg"),
    },
    {
      src: require("../assets/images/logoDesignImages/7.jpg"),
    },
    {
      src: require("../assets/images/logoDesignImages/5.jpg"),
    },
  ];
  return (
    <div>
      <PortfolioBanner BannerData={BannerData} />

      <div className="portfolio-inner py-5">
        <div className="text-center text-white">
          <h3
            className="font-face-Montserrat-Semi-Bold"
            data-aos="zoom-in"
            data-aos-duration="1300"
          >
            Imprints of Ingenuity
          </h3>
          <img src={horizontalLine} alt="hr" />
          <p
            className="font-face-Montserrat-Medium m-0"
            data-aos="zoom-in"
            data-aos-duration="1300"
          >
            Step into our prestigious logo design portfolio, where innovation
            converges with purpose. Immerse yourself in a gallery that
            <span className="d-lg-block">
              epitomizes our dedication to crafting brands into mesmerizing
              visual narratives. Every emblem mirrors painstaking
            </span>
            <span className="d-lg-block">
              precision and a profound grasp of design's potential to etch an
              indelible impact.
            </span>
          </p>
        </div>
      </div>

      <PortfolioGallery imageData={imageData} />

      <div className="pricing-cards text-white">
        <div className="container text-center pt-5">
          <h3
            className="font-face-Montserrat-Semi-Bold"
            data-aos="zoom-in"
            data-aos-duration="900"
          >
            PRICING
          </h3>
          <img src={horizontalLine} alt="hr" />
          <p
            className="font-face-Montserrat-Regular"
            data-aos="zoom-in"
            data-aos-duration="900"
          >
            <span className="d-lg-block">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
              tellus,{" "}
            </span>
            luctus nec ullamcorper mattis, pulvinar dapibus leo.
          </p>

          <div className="row py-5 px-lg-5">
            {pricingData.map((x, index) => {
              return (
                <div key={index} className="col-lg-4 mb-4">
                  <div className="inner py-5">
                    <h5 className="font-face-Montserrat-Medium py-3">
                      {x.heading}
                    </h5>
                    <img src={x.redIconImage} alt="red-icon" />
                    <h1 className="font-face-Montserrat-Bold py-3">
                      {x.priceText}
                    </h1>
                    <div className="d-flex align-items-start justify-content-center px-2">
                      <img src={x.tickIconImage} alt="tick-icon" />
                      <p className="font-face-Montserrat-Regular ms-3">
                        <span className="d-lg-block">{x.description1}</span>
                        {x.description2}
                      </p>
                    </div>
                    <div className="d-flex align-items-start justify-content-center px-2">
                      <img src={x.tickIconImage} alt="tick-icon" />
                      <p className="font-face-Montserrat-Regular ms-3">
                        <span className="d-lg-block">{x.description3}</span>
                        {x.description4}
                      </p>
                    </div>
                    <div className="d-flex align-items-start justify-content-center px-2">
                      <img src={x.tickIconImage} alt="tick-icon" />
                      <p className="font-face-Montserrat-Regular ms-3">
                        <span className="d-lg-block">{x.description5}</span>
                        {x.description6}
                      </p>
                    </div>
                    <div className="py-3">
                      <button className="font-face-Montserrat-Regular price-btn">
                        {x.buttonText}
                        <img
                          className="ms-2"
                          src={x.buttonIcon}
                          alt="right-icon"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogoDesigningPage;
