import React, { useEffect } from "react";
import PortfolioBanner from "../components/PortfolioBanner";
import CustomProcess from "../components/CustomProcess";
import "../components/CustomProcess.css";
import marketing1 from "../assets/images/customProcessImages/marketing1.png";
import marketing2 from "../assets/images/customProcessImages/marketing2.png";
import horizontalLine from "../assets/images/pricingImages/Line 1.png";
import Aos from "aos";
import "aos/dist/aos.css";

const MarketingPage = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const BannerData = {
    bannerHeading: "Branding & Marketing",
    bannerImg: require("../assets/images/customProcessImages/marketingBanner.jpg"),
    bannerParaOne:
      "Branding and marketing are two distinct but closely related concepts in the world of ",
    bannerParaTwo:
      "business and commerce. They play crucial roles in establishing and promoting a company's ",
    bannerParaThree:
      "products or services, building a strong reputation, and ultimately attracting and retaining customers.",
  };

  return (
    <div>
      <PortfolioBanner BannerData={BannerData} />
      <div className="portfolio-inner py-5">
        <div className="text-center text-white">
          <h3
            className="font-face-Montserrat-Semi-Bold"
            data-aos="zoom-in"
            data-aos-duration="1300"
          >
            Proven Process of Branding and Social Media Marketing
          </h3>
          <img src={horizontalLine} alt="hr" />
          <p
            className="font-face-Montserrat-Medium m-0"
            data-aos="zoom-in"
            data-aos-duration="1300"
          >
            Creating a successful branding and social media marketing strategy
            for platforms like Facebook, Instagram, and YouTube
            <span className="d-lg-block">
              requires a strategic approach tailored to the unique
              characteristics of each platform. Here's a proven process for
              branding
            </span>
            <span className="d-lg-block">
              and social media marketing on these platforms
            </span>
          </p>
        </div>
      </div>
      <div className="custom-process">
        <div className="container py-5">
          <div className="row custom-process-content">
            <div className="col-lg-6">
              <div>
                <p className="font-face-Montserrat-Bold">
                  1. Brand Analysis and Strategy
                </p>
                <ul className="text-white">
                  <li className="font-face-Montserrat-Medium">
                    <span style={{ color: "red" }}>
                      Define Your Brand Identity: &nbsp;
                    </span>
                    Start by clearly defining your brand's identity, values, and
                    unique selling points (USPs).
                  </li>
                  <li className="font-face-Montserrat-Medium">
                    <span style={{ color: "red" }}>
                      Audience Segmentation: &nbsp;
                    </span>
                    Identify your target audience on each platform, considering
                    demographics, interests, and behaviors.
                  </li>
                  <li className="font-face-Montserrat-Medium">
                    <span style={{ color: "red" }}>
                      Platform Selection:&nbsp;
                    </span>
                    Choose the most relevant platforms based on your audience
                    and business goals.
                  </li>
                  <li className="font-face-Montserrat-Medium">
                    <span style={{ color: "red" }}>
                      Content Strategy:&nbsp;
                    </span>
                    Develop a content strategy that aligns with your brand and
                    resonates with your target audience on each platform.
                  </li>
                </ul>
              </div>

              <div>
                <p className="font-face-Montserrat-Bold">
                  2. Profile Optimization
                </p>
                <ul className="text-white">
                  <li className="font-face-Montserrat-Medium">
                    <span style={{ color: "red" }}>
                      Profile Consistency: &nbsp;
                    </span>
                    Ensure your profiles on Facebook, Instagram, and YouTube are
                    consistent in terms of branding, including profile pictures,
                    cover images, and bios.
                  </li>
                  <li className="font-face-Montserrat-Medium">
                    <span style={{ color: "red" }}>
                      Keyword Optimization:&nbsp;
                    </span>
                    Use relevant keywords and hashtags in your profiles and bios
                    to improve discoverability.
                  </li>
                  <li className="font-face-Montserrat-Medium">
                    <span style={{ color: "red" }}>Verification:&nbsp;</span>
                    If applicable and eligible, seek verification on platforms
                    like Instagram and Facebook to establish credibility.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 d-flex flex-column align-items-center justify-content-center px-lg-5">
              <img className="img-fluid" src={marketing1} alt="1" />
            </div>
          </div>

          <div className="row custom-process-content-reverse mt-5">
            <div className="col-lg-6 d-flex flex-column align-items-center justify-content-center">
              <img className="img-fluid" src={marketing2} alt="1" />
            </div>
            <div className="col-lg-6">
              <div>
                <p className="font-face-Montserrat-Bold">
                  3. Content Creation and Planning
                </p>
                <ul className="text-white">
                  <li className="font-face-Montserrat-Medium">
                    <span style={{ color: "red" }}>
                      Content Calendar: &nbsp;
                    </span>
                    Create a content calendar that outlines when and what type
                    of content you will post on each platform.
                  </li>
                  <li className="font-face-Montserrat-Medium">
                    <span style={{ color: "red" }}>
                      Visual Storytelling: &nbsp;
                    </span>
                    Use compelling visuals, such as high-quality images and
                    videos, to tell your brand's story effectively.
                  </li>
                  <li className="font-face-Montserrat-Medium">
                    <span style={{ color: "red" }}>
                      Engaging Content:&nbsp;
                    </span>
                    Create content that encourages likes, comments, shares, and
                    follows.
                  </li>
                  <li className="font-face-Montserrat-Medium">
                    <span style={{ color: "red" }}>Variety:&nbsp;</span>
                    Diversify content types, including images, videos, stories,
                    reels, and live streams, to keep your audience engaged.
                  </li>
                </ul>
              </div>

              <div>
                <p className="font-face-Montserrat-Bold">
                  4. Advertising and Promotion
                </p>
                <ul className="text-white">
                  <li className="font-face-Montserrat-Medium">
                    <span style={{ color: "red" }}>
                      Paid Advertising:&nbsp;
                    </span>
                    Use platform-specific advertising options to target your
                    audience effectively.
                  </li>
                  <li className="font-face-Montserrat-Medium">
                    <span style={{ color: "red" }}>A/B Testing:&nbsp;</span>
                    Experiment with different ad creatives, audiences, and ad
                    formats to optimize performance.
                  </li>
                  <li className="font-face-Montserrat-Medium">
                    <span style={{ color: "red" }}>
                      Budget Management::&nbsp;
                    </span>
                    Allocate and manage your advertising budget efficiently.
                  </li>
                </ul>
              </div>

              <div>
                <p className="font-face-Montserrat-Bold">
                  5. Analytics and Measurement
                </p>
                <ul className="text-white">
                  <li className="font-face-Montserrat-Medium">
                    <span style={{ color: "red" }}>Data Tracking:&nbsp;</span>
                    Use built-in analytics tools on each platform to monitor
                    engagement, reach, and conversion metrics.
                  </li>
                  <li className="font-face-Montserrat-Medium">
                    <span style={{ color: "red" }}>Goal Assessment:&nbsp;</span>
                    Regularly assess whether your social media efforts are
                    aligning with your branding and marketing goals.
                  </li>
                  <li className="font-face-Montserrat-Medium">
                    <span style={{ color: "red" }}>Adjustment:&nbsp;</span>
                    Make data-driven adjustments to your strategy based on the
                    insights you gather.
                  </li>
                </ul>
              </div>

              <div>
                <p className="font-face-Montserrat-Bold">
                  6. Community Engagement
                </p>
                <ul className="text-white">
                  <li className="font-face-Montserrat-Medium">
                    <span style={{ color: "red" }}>
                      Respond to Comments:&nbsp;
                    </span>
                    Engage with your audience by responding to comments and
                    messages promptly.
                  </li>

                  <li className="font-face-Montserrat-Medium">
                    <span style={{ color: "red" }}>
                      Community Building:&nbsp;
                    </span>
                    Foster a sense of community by creating discussions and
                    encouraging user-generated content.
                  </li>
                  <li className="font-face-Montserrat-Medium">
                    <span style={{ color: "red" }}>
                      Influencer Collaboration:&nbsp;
                    </span>
                    Collaborate with influencers who align with your brand to
                    expand your reach.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketingPage;
