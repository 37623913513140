import React, { useEffect } from "react";
import aboutleft from "../assets/images/aboutImages/Group 87.png";
import "./AboutSection.css";
import { useNavigate } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";

const AboutSection = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  });
  const navigate = useNavigate();

  const goToContact = () => {
    navigate("/contact");
  };
  return (
    <div className="about-section-main text-white">
      <div className="container py-5">
        <div className="row">
          <div className="col-lg-6">
            <img className="img-fluid" src={aboutleft} alt="img" />
          </div>
          <div className="col-lg-6 d-flex flex-column justify-content-center align-items-start">
            <p
              className="font-face-Montserrat-Bold about-section-heading py-2"
              data-aos="zoom-in"
              data-aos-duration="1200"
            >
              What Is Patronus Pixels ?
            </p>
            <p
              className="font-face-Montserrat-Medium"
              data-aos="zoom-in"
              data-aos-duration="1200"
            >
              A Brand By Maisum Abbas that Elevates Brands through
              <span className="d-lg-block">
                Captivating Graphics and Intuitive UI/UX Designs for Mobile
              </span>
              and Web.
            </p>
            <p
              className="font-face-Montserrat-Medium"
              data-aos="zoom-in"
              data-aos-duration="1200"
            >
              At PatronusPixel, we believe that every brand has a unique
              <span className="d-lg-block">
                story waiting to be told. Our mission is to channel the essence
              </span>
              of your vision, infusing it with pixel-perfect magic to create
              <span className="d-lg-block">
                designs that captivate and inspire. With a touch of
              </span>
              enchantment and a dash of innovation, we bring your brand to
              <span className="d-lg-block">
                life through mesmerizing graphics and seamless UI/UX designs
              </span>
              for both mobile and web platforms.
            </p>
            <button
              className="font-face-Montserrat-Regular about-section-btn"
              data-aos="zoom-in"
              data-aos-duration="1200"
              onClick={goToContact}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
