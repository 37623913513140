import React, { useEffect } from "react";
import "../components/MultiMetricTracker.css";
import CountUp from "react-countup";
import horizontalLine from "../assets/images/pricingImages/Line 1.png";
import Aos from "aos";
import "aos/dist/aos.css";

const MultiMetricTracker = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  });
  return (
    <div className="counter-styling">
      <div class="container p-5">
        <div className="text-center text-white">
          <h3
            className="font-face-Montserrat-Semi-Bold"
            data-aos="zoom-in"
            data-aos-duration="1300"
          >
            COUNT IN NUMBERS
          </h3>
          <img src={horizontalLine} alt="hr" />
          <p
            className="font-face-Montserrat-Regular"
            data-aos="zoom-in"
            data-aos-duration="1300"
          >
            <span className="d-lg-block">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
              tellus
            </span>
            luctus nec ullamcorper mattis, pulvinar dapibus leo.
          </p>
        </div>
        <div class="row text-center py-5">
          <div class="col-md-3 col-6">
            <CountUp start={0} end={80} duration={2} className="count-title" />

            <p class="font-face-Montserrat-Regular count-text ">PROJECTS</p>
          </div>

          <div class="col-md-3 col-6">
            <CountUp start={0} end={20} duration={2} className="count-title" />

            <p class="font-face-Montserrat-Regular count-text ">CLIENTS</p>
          </div>

          <div class="col-md-3 col-6">
            <CountUp start={0} end={14} duration={2} className="count-title" />

            <p class="font-face-Montserrat-Regular count-text ">AWARDS</p>
          </div>

          <div class="col-md-3 col-6">
            <CountUp start={0} end={32} duration={2} className="count-title" />

            <p class="font-face-Montserrat-Regular count-text ">REPOSITORIES</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultiMetricTracker;
