import React from "react";
import facebookIcon from "../assets/images/footerImages/Group 35.png";
import randomIcon from "../assets/images/footerImages/Group 36.png";
import twitterIcon from "../assets/images/footerImages/Group 37.png";
import linkdinIcon from "../assets/images/footerImages/Group 38.png";

const Footer = () => {
  return (
    <div className="footer-main text-white text-center">
      <div className="container">
        <p className="font-face-Montserrat-Medium footer-heading footer-cursor py-2">
          Get In Touch With Me
        </p>
        <p className="font-face-Montserrat-Regular footer-cursor">Victoria Street No 1, London</p>
        <p className="font-face-Montserrat-Regular footer-cursor">contactinfo@mobdev.test</p>
        <p className="font-face-Montserrat-Regular footer-cursor">(001) 1231 3453</p>
        <div className="footer-icons footer-cursor py-3">
          <img
            className="px-2"
            onClick={() => alert("Working fine...")}
            src={facebookIcon}
            alt="fb"
          />
          <img
            className="px-2"
            onClick={() => alert("Working fine...")}
            src={randomIcon}
            alt="rn"
          />
          <img
            className="px-2"
            onClick={() => alert("Working fine...")}
            src={twitterIcon}
            alt="tw"
          />
          <img
            className="px-2"
            onClick={() => alert("Working fine...")}
            src={linkdinIcon}
            alt="ln"
          />
        </div>
      </div>
    </div>
  );
};

export default Footer;
