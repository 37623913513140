import React from 'react'
import AboutSection from '../components/AboutSection';
import Experience from "../components/Experience";
import HighlightedWorks from '../components/HighlightedWorks';
import MultiMetricTracker from '../components/MultiMetricTracker';

const AboutPage = () => {
  return (
    <div>
      <AboutSection />
      <div>
        <Experience />
      </div>
      <div>
        <HighlightedWorks />
      </div>
      <div>
        <MultiMetricTracker/>
      </div>
    </div>
  )
}

export default AboutPage
