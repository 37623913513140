import React, { useEffect } from "react";
import "../components/Offerings.css";
import Navbar from "./Navbar";

import arrowIcon from "../assets/images/offeringsImages/arrowIcon.png";

import horizontalLine from "../assets/images/pricingImages/Line 1.png";
import Aos from "aos";
import "aos/dist/aos.css";

const Offerings = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const offeringsData = [
    {
      id: 1,
      title: "Stationary Designing",
      description:
        "Elevate your brand's presence with meticulously crafted stationery designs that leave a lasting impression on every interaction.",
      link: "/stationery-design",
      image: require("../assets/images/offeringsImages/1.png"),
    },
    {
      id: 2,
      title: "Logo Designing",
      description:
        "Elevate your brand with a logo that's not just a symbol, but a story. Our designs intertwine uniqueness and allure.",
      link: "/logo-design",
      image: require("../assets/images/offeringsImages/2.png"),
    },
    {
      id: 3,
      title: "UI / UX Design",
      description:
        "Embark on a Voyage Through Our Esteemed UI/UX Design Portfolio: Where Ingenuity Blends with Purpose to Breathe.",
      link: "/uiux-design",
      image: require("../assets/images/offeringsImages/3.png"),
    },
    {
      id: 4,
      title: "Website Designing",
      description:
        "Embark on a Journey Through Our Esteemed Website Design Portfolio: Where Creativity Blends with Purpose to Bring Websites to Life.",
      link: "/website-design",
      image: require("../assets/images/offeringsImages/4.png"),
    },
    {
      id: 5,
      title: "Branding & Marketing",
      description:
        "Branding and marketing are two distinct but closely related concepts in the world of business and commerce.",
      link: "/marketing",
      image: require("../assets/images/offeringsImages/5.png"),
    },
    {
      id: 6,
      title: "Mobile App Development",
      description:
        "Mobile application development and marketing are two distinct but closely related concepts in the world of technology.",
      link: "/mobile-app-development",
      image: require("../assets/images/offeringsImages/6.png"),
    },
  ];

  return (
    <>
      <div className="boxStyling">
        <div className="container">
          <div>
            <h3
              className="font-face-Montserrat-Semi-Bold text-white"
              data-aos="fade-right"
              data-aos-duration="900"
            >
              SERVICES
            </h3>
            <img src={horizontalLine} alt="hr" />
          </div>

          <div className="row">
            <div className="text-center pt-5">
              <h3
                className="font-face-Montserrat-Semi-Bold text-white"
                data-aos="zoom-in"
                data-aos-duration="900"
              >
                WHAT WE DO
              </h3>
              <img src={horizontalLine} alt="hr" />
              <p
                className="font-face-Montserrat-Regular text-white"
                data-aos="zoom-in"
                data-aos-duration="900"
              >
                <span className="d-lg-block">
                  Your One-Stop Destination for Expert Branding, Web, and Mobile
                </span>
                Application Design & Development Services
              </p>
            </div>
            {offeringsData.map((x, index) => (
              <div
                className="col-lg-4 col-md-4 col-sm-4 col-xs-12"
                key={index}
                data-aos="zoom-in"
                data-aos-duration="1500"
              >
                <div className="box-part-offering text-start">
                  <img src={x.image} className="img-fluid mb-4" />

                  <div className="offering-title">
                    <h5 className="font-face-Montserrat-Medium">{x.title}</h5>
                  </div>

                  <div className="offering-text">
                    <span className="font-face-Montserrat-Regular">
                      {x.description}
                    </span>
                  </div>

                  <a className="font-face-Montserrat-Medium" href={x.link}>
                    Learn More
                    <img src={arrowIcon} className="img-fluid ms-2" />
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Offerings;
