import React from "react";

import ServiceOfferings from "../components/ServiceOfferings";
import PricingCards from "../components/PricingCards";
import SelectedWork from "../components/SelectedWorkSection";
import ClientTestimony from "../components/ClientTestimony";

import HeroImage from "../components/HeroImage";

const HomePage = () => {
  return (
    <>
      <div>
        <HeroImage />
      </div>
      <div>
        <ServiceOfferings />
      </div>
      <div>
        <PricingCards />
      </div>
      <div>
        <SelectedWork />
      </div>
      <div>
        <ClientTestimony />
      </div>
    </>
  );
};

export default HomePage;
