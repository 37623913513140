import React from "react";
import "./DesignBenefits.css";
import { useNavigate } from "react-router-dom";

const DesignBenefits = () => {
  const navigate = useNavigate();
  const goToContact = () => {
    navigate("/contact");
  };
  return (
    <div className="text-white" style={{ backgroundColor: "#202020" }}>
      <div className="container py-5">
        <div className="row px-lg-5 ">
          <div className="col-lg-12 px-lg-2">
            <div className="testimonial-contact-inner px-5 px-lg-5 px-md-5 px-sm-1 py-4">
              <h3 className="font-face-Montserrat-Semi-Bold m-0">
                Discover How Design
                <span className="d-lg-block">Can Help Your </span> Business
              </h3>
              <button className="font-face-Montserrat-Regular testimonial-btn" onClick={goToContact}>
                Contact Us
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesignBenefits;
